import "leaflet/dist/leaflet.css";
import "@popperjs/core";
import "jquery-ui/ui/widgets/datepicker";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import SolarMap from "./pages/SolarMap/SolarMap";
import { useEffect, useState } from "react";
import { isTokenExpired } from "./utils/auth";
import {ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import { MapProvider } from "./utils/MapContext";
import { Routes, Route } from "react-router-dom";
import SignIn from "./pages/SignIn/SignIn";
import HomePage from "./pages/Home/HomePage";
// import NavBar from "./component/NavBar";

function App() {
  const [theme, colorMode] = useMode();
  const [isConnected, setIsConnected] = useState(false);
  useEffect(() => {
    const token = localStorage.getItem("access_token");
    const expired = isTokenExpired(token);
    setIsConnected(token && !expired);
  }, []);
  return (
    <MapProvider>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          
          <Routes>
            <Route exact path="/demo" omponent={<HomePage />} />
            <Route path="/" element={isConnected ? <SolarMap /> : <SignIn />} />
          </Routes>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </MapProvider>
  );
}
export default App;

import React, { createContext, useState, useMemo , useContext} from 'react';

// Create the context
export const MapContext = createContext();

export const useAuth = () => {
  return useContext(MapContext);
};
// Provider component
export const MapProvider = ({ children }) => {
  const [timeFrame, setTimeFrame] = useState('yearly');  // Default value
  const [isAuthenticated, setIsAuthenticated] = useState(false);


  // Memoize the context value to optimize performance
  const value = useMemo(() => ({
    timeFrame,
    setTimeFrame
  }), [timeFrame]);

  const login = (token) => {
    setIsAuthenticated(true);
  };

  const logout = () => {
    setIsAuthenticated(false);
  };

  return (
    <MapContext.Provider value={{value,isAuthenticated,  login, logout}}>
      {children}
    </MapContext.Provider>
  );
};
// import React from 'react';
import axios from "axios";
import 'react-toastify/dist/ReactToastify.css';

const isAuthapi = axios.create({
  // baseURL: process.env.REACT_APP_API_URL_DEV_ISAUTH || "http://localhost:5000"
  baseURL: process.env.REACT_APP_API_URL_DEV_ISAUTH || "https://gateway.windmyroof.com"
  ,
  withCredentials: true,
});



isAuthapi.interceptors.request.use((request) => {
  if (request.headers)
    request.headers["Authorization"] = `Bearer ${localStorage.getItem(
      "access_token"
    )}`;
  return request;
});
isAuthapi.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
   
    if (error.response.status !== 401) {
        return Promise.reject(error);
      } 
        
        try {
          const response = await isAuthapi.get("users/auth/refresh");
          const token = response.data.token;
          localStorage.setItem("token", token);
          error.hasRefreshedToken = true;
          return Promise.reject(error);
        } catch (error) {
          const tokenError = new Error("Cannot refresh token");
          return Promise.reject(tokenError);
        }
      
    
  }
);
isAuthapi.interceptors.response.use(
  response => response,
  error => {
    // console.error("Axios Error:", error.response || error);
    return Promise.reject(error);
  }
);

export default isAuthapi;


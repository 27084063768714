import axios from "axios";
import "react-toastify/dist/ReactToastify.css";

const api = axios.create({
  // baseURL: process.env.REACT_APP_API_URL_APISSWMR || "http://localhost:9000/api/",
  baseURL: process.env.REACT_APP_API_URL_APISSWMR || "https://api-surface-study.windmyroof.com/api/",
  withCredentials: true,
});

api.interceptors.request.use((request) => {
  const token = localStorage.getItem("access_token");
  if (token) {
    request.headers["Authorization"] = `Bearer ${token}`;
  }
  return request;
});

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      try {
        const response = await api.get("users/auth/refresh");
        const { access_token } = response.data;
        localStorage.setItem("access_token", access_token);
        return api(originalRequest); // Retry the original request with the new token
      } catch (refreshError) {
        localStorage.removeItem("access_token"); // Remove invalid token
        // Redirect to login page or show login modal here
        // For redirection, you might need to use window.location or history.push if you're within a React component
        window.location.href = "/"; // Change "/login" to your login route
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

export default api;

// import React, { useState } from 'react';
// import { AppBar, Toolbar, IconButton, Typography, Button, Drawer, List, ListItem, ListItemText, useMediaQuery } from '@mui/material';
// import MenuIcon from '@mui/icons-material/Menu';
// import { useTheme } from '@mui/material/styles';
// import { Link } from 'react-router-dom';

// const NavBar = () => {
//   const [drawerOpen, setDrawerOpen] = useState(false);
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down('md'));

//   const toggleDrawer = (open) => (event) => {
//     if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
//       return;
//     }
//     setDrawerOpen(open);
//   };

//   const drawer = (
//     <div
//       role="presentation"
//       onClick={toggleDrawer(false)}
//       onKeyDown={toggleDrawer(false)}
//     >
//       <List>
//         <ListItem button component={Link} to="/">
//           <ListItemText primary="Page d'accueil" />
//         </ListItem>
//         <ListItem button component={Link} to="/benefits">
//           <ListItemText primary="Bénéfices" />
//         </ListItem>
//         <ListItem button component={Link} to="/use-cases">
//           <ListItemText primary="Cas d'usage" />
//         </ListItem>
//         <ListItem button component={Link} to="/interface">
//           <ListItemText primary="Interface" />
//         </ListItem>
//         <ListItem button component={Link} to="/partners-clients">
//           <ListItemText primary="Partenaires et Clients" />
//         </ListItem>
//         <ListItem button component={Link} to="/contact">
//           <ListItemText primary="Contact" />
//         </ListItem>
//       </List>
//     </div>
//   );

//   return (
//     <>
//       <AppBar position="static" sx={{ background: 'linear-gradient(to right, #2536a394, #24dc7a73)' }}>
//         <Toolbar>
//           <Typography variant="h6" sx={{ flexGrow: 1 }}>
//             muchbetter
//           </Typography>
//           {isMobile ? (
//             <IconButton
//               edge="start"
//               color="inherit"
//               aria-label="menu"
//               onClick={toggleDrawer(true)}
//             >
//               <MenuIcon />
//             </IconButton>
//           ) : (
//             <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'space-around' }}>
//               <Button color="inherit" component={Link} to="/">Page d'accueil</Button>
//               <Button color="inherit" component={Link} to="/benefits">Bénéfices</Button>
//               <Button color="inherit" component={Link} to="/use-cases">Cas d'usage</Button>
//               <Button color="inherit" component={Link} to="/interface">Interface</Button>
//               <Button color="inherit" component={Link} to="/partners-clients">Partenaires et Clients</Button>
//               <Button color="inherit" component={Link} to="/contact">Contact</Button>
//               <Button color="inherit" component={Link} to="/demo">Demo</Button>
//             </div>
//           )}
//         </Toolbar>
//       </AppBar>
//       <Drawer
//         anchor="top"
//         open={drawerOpen}
//         onClose={toggleDrawer(false)}
//       >
//         {drawer}
//       </Drawer>
//     </>
//   );
// };

// export default NavBar;

////////////////////////////////////////////////////////////////////////////////////////////////////

// import React, { useState } from 'react';
// import { AppBar, Toolbar, IconButton, Typography, Button, Drawer, List, ListItem, ListItemText, useMediaQuery } from '@mui/material';
// import MenuIcon from '@mui/icons-material/Menu';
// import { useTheme } from '@mui/material/styles';
// import { Link } from 'react-router-dom';

// const NavBar = () => {
//   const [drawerOpen, setDrawerOpen] = useState(false);
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down('md'));

//   const toggleDrawer = (open) => (event) => {
//     if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
//       return;
//     }
//     setDrawerOpen(open);
//   };

//   const drawer = (
//     <div
//       role="presentation"
//       onClick={toggleDrawer(false)}
//       onKeyDown={toggleDrawer(false)}
//     >
//       <List>
//         <ListItem button component={Link} to="/">
//           <ListItemText primary="Page d'accueil" />
//         </ListItem>
//         <ListItem button component={Link} to="/benefits">
//           <ListItemText primary="Bénéfices" />
//         </ListItem>
//         <ListItem button component={Link} to="/use-cases">
//           <ListItemText primary="Cas d'usage" />
//         </ListItem>
//         <ListItem button component={Link} to="/interface">
//           <ListItemText primary="Interface" />
//         </ListItem>
//         <ListItem button component={Link} to="/partners-clients">
//           <ListItemText primary="Partenaires et Clients" />
//         </ListItem>
//         <ListItem button component={Link} to="/contact">
//           <ListItemText primary="Contact" />
//         </ListItem>
//         <ListItem button component={Link} to="/demo">
//           <ListItemText primary="Demo" />
//         </ListItem>
//       </List>
//     </div>
//   );

//   return (
//     <>
//       <AppBar position="static" sx={{ background: 'linear-gradient(to right, #2536a394, #24dc7a73)' }}>
//         <Toolbar>
//           <Typography variant="h6" sx={{ flexGrow: 1 }}>
//             muchbetter
//           </Typography>
//           {isMobile ? (
//             <IconButton
//               edge="start"
//               color="inherit"
//               aria-label="menu"
//               onClick={toggleDrawer(true)}
//             >
//               <MenuIcon />
//             </IconButton>
//           ) : (
//             <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'space-around' }}>
//               <Button color="inherit" component={Link} to="/">Page d'accueil</Button>
//               <Button color="inherit" component={Link} to="/benefits">Bénéfices</Button>
//               <Button color="inherit" component={Link} to="/use-cases">Cas d'usage</Button>
//               <Button color="inherit" component={Link} to="/interface">Interface</Button>
//               <Button color="inherit" component={Link} to="/partners-clients">Partenaires et Clients</Button>
//               <Button color="inherit" component={Link} to="/contact">Contact</Button>
//               <Button color="inherit" component={Link} to="/demo">Demo</Button>
//             </div>
//           )}
//         </Toolbar>
//       </AppBar>
//       <Drawer
//         anchor="top"
//         open={drawerOpen}
//         onClose={toggleDrawer(false)}
//       >
//         {drawer}
//       </Drawer>
//     </>
//   );
// };

// export default NavBar;
import React, { useState } from 'react';
import { AppBar, Toolbar, IconButton, Typography, Button, Drawer, List, ListItem, ListItemText, useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useTheme } from '@mui/material/styles';
import { Link as ScrollLink } from 'react-scroll';

const NavBar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navOffset = -64; // Adjust this value according to your navbar height

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const drawer = (
    <div
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItem button>
          <ScrollLink to="home" smooth duration={500} offset={navOffset}>
            <ListItemText primary="Page d'accueil" />
          </ScrollLink>
        </ListItem>
        <ListItem button>
          <ScrollLink to="benefits" smooth duration={500} offset={navOffset}>
            <ListItemText primary="Bénéfices" />
          </ScrollLink>
        </ListItem>
        <ListItem button>
          <ScrollLink to="useCases" smooth duration={500} offset={navOffset}>
            <ListItemText primary="Cas d'usage" />
          </ScrollLink>
        </ListItem>
        <ListItem button>
          <ScrollLink to="interface" smooth duration={500} offset={navOffset}>
            <ListItemText primary="Interface" />
          </ScrollLink>
        </ListItem>
        <ListItem button>
          <ScrollLink to="partnersClients" smooth duration={500} offset={navOffset}>
            <ListItemText primary="Partenaires et Clients" />
          </ScrollLink>
        </ListItem>
        <ListItem button>
          <ScrollLink to="contact" smooth duration={500} offset={navOffset}>
            <ListItemText primary="Contact" />
          </ScrollLink>
        </ListItem>
        <ListItem button>
          <ScrollLink to="demo" smooth duration={500} offset={navOffset}>
            <ListItemText primary="Demo" />
          </ScrollLink>
        </ListItem>
      </List>
    </div>
  );

  return (
    <>
      <AppBar position="fixed" sx={{ background: 'linear-gradient(to right, #2536a394, #24dc7a73)' }}>
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
          study of the territory
          </Typography>
          {isMobile ? (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
          ) : (
            <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'space-around' }}>
              <Button color="inherit">
                <ScrollLink to="home" smooth duration={500} offset={navOffset}>Page d'accueil</ScrollLink>
              </Button>
              <Button color="inherit">
                <ScrollLink to="benefits" smooth duration={500} offset={navOffset}>Bénéfices</ScrollLink>
              </Button>
              <Button color="inherit">
                <ScrollLink to="useCases" smooth duration={500} offset={navOffset}>Cas d'usage</ScrollLink>
              </Button>
              <Button color="inherit">
                <ScrollLink to="interface" smooth duration={500} offset={navOffset}>Interface</ScrollLink>
              </Button>
              <Button color="inherit">
                <ScrollLink to="partnersClients" smooth duration={500} offset={navOffset}>Partenaires et Clients</ScrollLink>
              </Button>
              <Button color="inherit">
                <ScrollLink to="contact" smooth duration={500} offset={navOffset}>Contact</ScrollLink>
              </Button>
              <Button color="inherit">
                <ScrollLink to="demo" smooth duration={500} offset={navOffset}>LogOut</ScrollLink>
              </Button>
            </div>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="top"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
      >
        {drawer}
      </Drawer>
    </>
  );
};

export default NavBar;





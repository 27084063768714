// const tourSteps = [
//   {
//     target: '.leaflet-container',
//     content: 'This is the map area where you can see the buildings and their solar potential click in the roof cololr to see detaile.',
//     disableBeacon: true,
//   },
//   {
//     target: '.search-input ',
//     content: 'This is the search bar where you can search for addresses.',
//   },
//   {
//     target: '.sidebar-container',
//     content: 'This sidebar provides detailed information about the buildings.',
//   },
//   {
//     target: '.sidebar-container-eye-left',
//     content: 'This sidebar sidebar-container-eye-left weher t see button to swech and find the solar and wind button .',
//   },
//   {
//     target: '.legend-container',
//     content: 'This legend explains the color coding used on the map.',
//   }
// ];

// export default tourSteps;


const tourSteps = [
  {
    target: '.leaflet-container',
    content: 'This is the map area where you can see the buildings and their solar potential. Click on the roof colors to see detailed information.',
    disableBeacon: true,
  },
  {
    target: '.search-input',
    content: 'This is the search bar where you can search for addresses.',
  },
  {
    target: '.sidebar-container-eye-left',
    content: 'This is the sidebar where you can switch between solar and wind information using the buttons.',
  },
  {
    target: '.sidebar-container',
    content: 'This sidebar provides detailed information about the buildings.',
  },
 
  {
    target: '.legend-container',
    content: 'This legend explains the color coding used on the map.',
  }
];

export default tourSteps;


import React from 'react';
import Joyride from 'react-joyride';
import tourSteps from './tourSteps';  // Import the tour steps

const Tour = ({ run, setRun }) => {
  return (
    <Joyride
      steps={tourSteps}
      run={run}
      continuous={true}
      showProgress={true}
      showSkipButton={true}
      locale={{
        last: 'Finish',
      }}
      callback={(data) => {
        const { action } = data;
        if (action === 'skip' || action === 'close') {
          setRun(false);
        }
      }}
      styles={{
        options: {
          zIndex: 10003, // Higher than other elements
          arrowColor: "#5caeab",
          backgroundColor: "#5caeab",
          overlayColor: "rgba(92, 174, 171, .3)",
          primaryColor: "#5caeab",
          textColor: "#fff",
        },
        spotlight: {
          backgroundColor: "transparent",
        },
      }}
    />
  );
};

export default Tour;

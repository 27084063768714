import React from 'react';
import { Container, Box, Typography } from '@mui/material';
import Footer from '../../component/Components/Footer';

const HomePage = () => (
  <div>

 
  <Container>
    <section id="home" style={{ padding: '100px 0' }}>
      <Box textAlign="center">
        <Typography variant="h3" gutterBottom>
          Page d'accueil
        </Typography>
        <Typography variant="body1">
          Welcome to the home page.
        </Typography>
      </Box>
    </section>
    <section id="benefits" style={{ padding: '100px 0', backgroundColor: '#f9f9f9' }}>
      <Box textAlign="center">
        <Typography variant="h3" gutterBottom>
          Bénéfices
        </Typography>
        <Typography variant="body1">
          Details about benefits.
        </Typography>
      </Box>
    </section>
    <section id="useCases" style={{ padding: '100px 0' }}>
      <Box textAlign="center">
        <Typography variant="h3" gutterBottom>
          Cas d'usage
        </Typography>
        <Typography variant="body1">
          Details about use cases.
        </Typography>
      </Box>
    </section>
    <section id="interface" style={{ padding: '100px 0', backgroundColor: '#f9f9f9' }}>
      <Box textAlign="center">
        <Typography variant="h3" gutterBottom>
          Interface
        </Typography>
        <Typography variant="body1">
          Details about the interface.
        </Typography>
      </Box>
    </section>
    <section id="partnersClients" style={{ padding: '100px 0' }}>
      <Box textAlign="center">
        <Typography variant="h3" gutterBottom>
          Partenaires et Clients
        </Typography>
        <Typography variant="body1">
          Details about partners and clients.
        </Typography>
      </Box>
    </section>
    <section id="contact" style={{ padding: '100px 0', backgroundColor: '#f99f9f9' }}>
      <Box textAlign="center">
        <Typography variant="h3" gutterBottom>
          Contact
        </Typography>
        <Typography variant="body1">
          Contact information.
        </Typography>
      </Box>
    </section>
    <section id="demo" style={{ padding: '100px 0' }}>
      <Box textAlign="center">
        <Typography variant="h3" gutterBottom>
          Demo
        </Typography>
        <Typography variant="body1">
          Demo details.
        </Typography>
      </Box>
    </section>
  </Container>
    <Footer />
  </div>
);

export default HomePage;

import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { MapContainer, TileLayer, Popup, Polygon } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { fetchDashAppURL, fetchBuildingsGeoJSONByUserId } from "../../services/geo";
import DashAppPopup from "../../component/DashAppPopup";
import Legend from "../../component/Legend";
import Sidebar from "../../component/Sidebar";
import MapSearchControl from "../../component/MapSearchControl";
import Tour from "../../component/Tour";
import { Icon } from "react-icons-kit";
import { eyeOff, eye, arrowLeft } from 'react-icons-kit/feather';
import { ic_wb_sunny as sunIcon, ic_autorenew as windIcon } from 'react-icons-kit/md'; 
import { leaf as leafIcon } from "react-icons-kit/fa";
import "../../assets/App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import CustomIconButton from "../../component/CustomIconButton";
import NavBar from "../../component/NavBar";
const SolarMap = () => {
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(eyeOff);
  const [iconopen, setIconOpen] = useState(eye);
  const [isPanelVisible, setIsPanelVisible] = useState(false);
  const [isOpenEye, setIsOpenEye] = useState(false);
  const [mapIcon, setMapIcon] = useState(sunIcon);
  const [mapLayer, setMapLayer] = useState("standard");
  const [currentLayer, setCurrentLayer] = useState("satellite");
  const [isButtonsVisible, setIsButtonsVisible] = useState(false);
  const [address, setAddress] = useState("");
  const [buildingsData, setBuildingsData] = useState([]);
  const [latitude, setLatitude] = useState(Number);
  const [popupDashAppURL, setPopupDashAppURL] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dashUrl, setDashUrl] = useState("");
  const [isDivVisible, setIsDivVisible] = useState(false);
  const [isSidebarVisible, setSidebarVisible] = useState(false);
  const [usePotentialColor, setUsePotentialColor] = useState(true);
  const [cityData, setCityData] = useState([]);
  const [run, setRun] = useState(true);

  const togglePanelVisibility = () => {
    setIsPanelVisible(!isPanelVisible);
  };

  const toggleDivVisibility = () => {
    setIsDivVisible(!isDivVisible);
  };

  const toggleSidebarVisibility = () => {
    setSidebarVisible(!isSidebarVisible);
  };

  const user = JSON.parse(localStorage.getItem("user"));
  const userId = user ? user.id : null;
  // console.log('userId', userId);

  const { data, isLoading, error } = useQuery(
    ["buildingsGeoJSON", userId],
    () => fetchBuildingsGeoJSONByUserId(userId),
    {
      enabled: !!userId, // Only run the query if userId is available
    }
  );

  useEffect(() => {
    if (data) {
      // console.log(data, "this is the response.data");

      const allBuildings = data.flatMap(city => city.buildings);
      const processedBuildings = allBuildings.map((building) => ({
        ...building,
        parsedLocation: parseWKT(building.location),
      }));

      setBuildingsData(processedBuildings);
      // console.log(processedBuildings, "this is the processed buildings data");
    }
  }, [data]);

  useEffect(() => {
    if (buildingsData.length > 0) {
      const fetchCityData = async () => {
        const cities = await Promise.all(
          buildingsData.map(async (building) => {
            const coordinates = building.parsedLocation[0];
            const city = await reverseGeocode(coordinates[1], coordinates[0]);
            return city;
          })
        );
        setCityData(cities);
      };
      fetchCityData();
    }
  }, [buildingsData]);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>An error occurred: {error.message}</div>;

  const parseWKT = (wkt) => {
    const coordinatesString = wkt.split("POLYGON ((")[1].split("))")[0];
    const coordinatesArray = coordinatesString.split(",").map((coordString) => {
      const [latitude, longitude] = coordString.trim().split(" ").map(Number);
      setLatitude(longitude);
      setLatitude(latitude);
      return [latitude, longitude];
    });
    return coordinatesArray;
  };

  const reverseGeocode = async (longitude, latitude) => {
    try {
      const response = await fetch(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&zoom=10`);
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const data = await response.json();
      
      return data.address.city || data.address.town || data.address.village || "Unknown";
    } catch (error) {
      console.error('Error:', error);
      return "Unknown"; // fallback in case of error
    }
  };
  
  const toggleLayer = () => {
    setCurrentLayer(currentLayer === "map" ? "satellite" : "map");
  };

  const handleToggle = () => {
    setIsOpenEye((prev) => !prev); // Toggle the eye state
    setIsButtonsVisible((prev) => !prev); // Toggle button visibility
  };
  const handleToggle2 = () => {
    setMapIcon((prevIcon) => (prevIcon === sunIcon ? windIcon : sunIcon)); // Toggle between sunIcon and windIcon
  };

  const toggleMapLayer = () => {
    setMapLayer(mapLayer === "standard" ? "satellite" : "standard");
  };

  const handlePopupClick = async (buildingId) => {
    try {
      const url = await fetchDashAppURL(buildingId);
      if (url) {
        setDashUrl(url);
        setPopupDashAppURL(url);
        setIsModalOpen(true);
      }
    } catch (error) {
      console.error("Error fetching Dash app URL:", error);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const interpolateColor = (color1, color2, factor) => {
    return color1.map((c, i) => c + factor * (color2[i] - c));
  };

  const getColorByPotential = (potential, min = 0, max = 1000) => {
    const red = [255, 0, 0];
    const rgbYellow = [255, 213, 48];
    const factor = (potential - min) / (max - min);
    const color = interpolateColor(red, rgbYellow, factor);
    return `rgb(${color[0]}, ${color[1]}, ${color[2]})`;
  };

  const getColorByZMinSol = (zMinSol) => {
    const minZ = 0;
    const maxZ = 1000;
    const blue = [0, 0, 255];
    const rgbYellow = [255, 213, 48];
    const factor = (zMinSol - minZ) / (maxZ - minZ);
    const color = interpolateColor(blue, rgbYellow, factor);
    return `rgb(${color[0]}, ${color[1]}, ${color[2]})`;
  };

  const getPolygonColor = (building) => {
    return usePotentialColor
      ? getColorByPotential(building.potential)
      : getColorByZMinSol(building.z_min_sol);
  };

  return (
    <div style={{ position: "relative", height: "100vh", width: "100%" }}>
      <NavBar />
      <CustomIconButton
        icon={arrowLeft}
        size={30}
        color="white"
        style={{
          position: "absolute",
          zIndex: 1000,
          top: "8%",
          right: 0,
          padding: "3px",
          borderRadius: "2.5px",
          backgroundColor: "#323232",
        }}
        onClick={() => setSidebarVisible(!isSidebarVisible)}
      />
      <Sidebar
        isVisible={isSidebarVisible}
        closeSidebar={toggleSidebarVisibility}
        usePotentialColor={usePotentialColor}
        setUsePotentialColor={setUsePotentialColor}
        allBuildings={buildingsData}
        cityData={cityData}
 
      />
      <div
        style={{
          position: "absolute",
          zIndex: 1000,
          top: "15%",
          left: 10,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <button
          className="sidebar-container-eye-left"
          onClick={handleToggle}
          style={{
            marginBottom: '1rem',
            padding: '10px',
            borderRadius: '5px',
            backgroundColor: '#323232',
          }}
        >
          <Icon style={{ color: 'white' }} icon={isOpenEye ? eye : eyeOff} size={30} />
        </button>
        {isButtonsVisible && (
          <button
            onClick={handleToggle2}
            style={{
              marginBottom: "1rem",
              padding: "10px",
              borderRadius: "5px",
              backgroundColor: "#323232",
            }}
          >
            <Icon style={{ color: "white" }} icon={mapIcon} size={30} />
          </button>
        )}
      </div>
      {isButtonsVisible && (
        <div
          style={{
            position: "absolute",
            zIndex: 1000,
            top: "29%",
            left: "0.6%",
            transform: "translateY(-50%)",
            background:
              "linear-gradient(to top, rgb(77, 69, 41), rgb(77, 69, 41) 17%, rgb(128, 104, 29) 33%, rgb(179, 139, 18) 50%, rgb(230, 174, 6) 67%, rgb(255, 213, 48) 83%, rgb(255, 255, 143) 100%)",
            padding: "6px",
            borderRadius: "5px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Icon
            icon={mapIcon}
            size={42}
            onClick={toggleMapLayer}
            style={{
              cursor: "pointer",
              marginBottom: "30px",
              color: "rgb(255, 215, 0)",
            }}
          />
          <Icon
            icon={mapIcon}
            size={42}
            onClick={toggleMapLayer}
            style={{
              cursor: "pointer",
              marginTop: "30px",
              color: "rgb(255, 215, 0)",
            }}
          />
        </div>
      )}
      <div style={{ position: "absolute", bottom: "10px", left: "0.5%" }}>
        <button
          onClick={toggleLayer}
          style={{
            width: "50px",
            height: "50px",
            backgroundImage: `url(${
              currentLayer === "map"
                ? process.env.PUBLIC_URL + "/satellite-thumbnail.png"
                : process.env.PUBLIC_URL + "/map-thumbnail.png"
            })`,
            backgroundSize: "cover",
            border: "none",
            cursor: "pointer",
            position: "absolute",
            zIndex: 1000,
            bottom: "1px",
            left: "1%",
          }}
        >
          <span style={{ visibility: "hidden" }}>
            {currentLayer === "map" ? "Satellite" : "Map"}
          </span>
        </button>
      </div>
      <MapContainer
      className="leaflet-container"
        center={[49.76601352123593, 2.624427051698388]}
        zoom={18}
        style={{ height: "100%", width: "100%" }}
        zoomControl={false}
        attributionControl={false}
      >
        <MapSearchControl setAddress={setAddress} />
        {currentLayer === "map" && (
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
        )}
        {currentLayer === "satellite" && (
          <TileLayer
            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
            attribution="Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
          />
        )}
        {buildingsData &&
          buildingsData.map((building, index) => (
            <Polygon
              key={index}
              positions={building.parsedLocation}
              pathOptions={{
                color: getPolygonColor(building),
                fillColor: getPolygonColor(building),
                fillOpacity: 0.7,
                weight: 1,
              }}
            >
              <Popup
                className="popup-container "
                onClick={() => handlePopupClick(building.id)}
                style={{ width: "400px" }}
              >
                <div style={{ marginBottom: "10px" }}>
                  <strong>{building.address}</strong>
                </div>
                <div
                  style={{
                    marginBottom: "10px",
                    backgroundColor: "rgba(0, 0, 0, 0.6)",
                    padding: "10px",
                    borderRadius: "5px",
                  }}
                >
                  <div>Répartition du potentiel solaire de la toiture</div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>Excellent</div>
                    <div
                      style={{
                        backgroundColor: "#FFD700",
                        width: "30px",
                        textAlign: "center",
                      }}
                    >
                      {building.excellent} m²
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>Très Bon</div>
                    <div
                      style={{
                        backgroundColor: "#FFC700",
                        width: "30px",
                        textAlign: "center",
                      }}
                    >
                      {building.tresBon} m²
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>Bon</div>
                    <div
                      style={{
                        backgroundColor: "#FFB700",
                        width: "30px",
                        textAlign: "center",
                      }}
                    >
                      {building.bon} m²
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>Moyen</div>
                    <div
                      style={{
                        backgroundColor: "#FFA700",
                        width: "30px",
                        textAlign: "center",
                      }}
                    >
                      {building.moyen} m²
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>Passable</div>
                    <div
                      style={{
                        backgroundColor: "#FF9700",
                        width: "30px",
                        textAlign: "center",
                      }}
                    >
                      {building.passable} m²
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>Mauvais</div>
                    <div
                      style={{
                        backgroundColor: "#FF8700",
                        width: "30px",
                        textAlign: "center",
                      }}
                    >
                      {building.mauvais} m²
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    marginBottom: "10px",
                    backgroundColor: "rgba(0, 0, 0, 0.6)",
                    padding: "10px",
                    borderRadius: "5px",
                  }}
                >
                  Surface totale: {building.totalSurface} m²
                </div>
                <button
                  onClick={() => handlePopupClick(building.id)}
                  style={{
                    width: "100%",
                    padding: "10px",
                    borderRadius: "5px",
                    backgroundColor: "#FF7F00",
                    color: "white",
                    border: "none",
                    cursor: "pointer",
                  }}
                >
                  Valider ma sélection
                </button>
              </Popup>
            </Polygon>
          ))}
      </MapContainer>
      <DashAppPopup isOpen={isModalOpen} dashUrl={dashUrl} closeModal={closeModal} />
      <Legend usePotentialColor={usePotentialColor} />
      <Tour run={run} setRun={setRun} />
    </div>
  );
};

export default SolarMap;
import React from 'react';
import { Container, Grid, Box, Typography, Link } from '@mui/material';

const Footer = () => (
  <Box component="footer" sx={{ backgroundColor: '#f9f9f9', py: 4 }}>
    <Container>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={4}>
          <Typography variant="h6" gutterBottom>Sections</Typography>
          <Link href="#home" underline="none" color="inherit">
            Accueil
          </Link><br />
          <Link href="#benefits" underline="none" color="inherit">
            Bénéfices
          </Link><br />
          <Link href="#useCases" underline="none" color="inherit">
            Cas d'usage
          </Link><br />
          <Link href="#interface" underline="none" color="inherit">
            Interface
          </Link>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="h6" gutterBottom>Contact</Typography>
          <Typography variant="body1">Contact@muchbetter.ai</Typography>
          <Typography variant="body1">+33 6 51 99 39 69</Typography>
          <Link href="https://www.linkedin.com" underline="none" color="inherit">
            LinkedIn
          </Link>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="h6" gutterBottom>Politique</Typography>
          <Link href="#" underline="none" color="inherit">
            Cookies
          </Link><br />
          <Link href="#" underline="none" color="inherit">
            Politique de confidentialité
          </Link>
          <Box sx={{ mt: 2 }}>
            <Link href="#" underline="none" color="inherit">
              <img src="path/to/flag-us.png" alt="English" width="24" />
            </Link>
            <Link href="#" underline="none" color="inherit" sx={{ ml: 2 }}>
              <img src="path/to/flag-fr.png" alt="Français" width="24" />
            </Link>
          </Box>
        </Grid>
      </Grid>
   
    </Container>
  </Box>
);

export default Footer;

import React from "react";
import Modal from "react-modal";
import Iframe from "react-iframe";

const DashAppPopup = ({ isOpen, dashUrl, closeModal }) => {
  // console.log(isOpen, dashUrl, closeModal);
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel="Dash Modal"
      // style={modalStyles}
      style={{
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 1000,
        },
        content: {
          position: "absolute",
          inset: "50%  50%",
          border: "none",
          background: "translate3d(877px, 321px, 0px)",
          overflow: "none",
          borderRadius: "4px",
          outline: "none",
          padding: "20px",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",

          width: "90vw", 
          height: "80vh",
        },
      }}
    >
      <p onClick={closeModal}>X</p>
      <Iframe url={dashUrl} width="800px" height="600px" />
    </Modal>
  );
};

export default DashAppPopup;

import React from 'react';

const Legend = ({ usePotentialColor }) => {
  const legendStyle = {
    position: 'absolute',
    zIndex: '1000',
    bottom: '10px',
    left: '9%',
    width: '300px',
    height: '30px',
    border: '2px solid black',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 10px',
    color: 'white',
    fontSize: '12px'
  };

  const potentialGradient = 'linear-gradient(to right, red, rgb(255, 213, 48))';
  const zMinSolGradient = 'linear-gradient(to right, blue, rgb(255, 213, 48))';

  return (
    <div style={{ ...legendStyle, background: usePotentialColor ? potentialGradient : zMinSolGradient }}>
      <span>min 0</span>
      <span>{usePotentialColor ? 'the progress color of potential' : 'the progress color of z_min_sol'}</span>
      <span>max 1000</span>
    </div>
  );
};

export default Legend;

import React from 'react';
import { Icon } from 'react-icons-kit';
import { eyeOff, eye, arrowLeft, ic_wb_sunny as sunIcon, ic_autorenew as windIcon } from 'react-icons-kit/feather';

const CustomIconButton = ({ icon = eyeOff, size = 30, color = 'white', onClick, ...props }) => (
  <button onClick={onClick} {...props}>
    <Icon icon={icon} size={size} style={{ color }} />
  </button>
);

export default CustomIconButton;

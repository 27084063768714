import React, { useState } from "react";
// import CloseButton from "react-bootstrap/CloseButton";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
// import Accordion from "react-bootstrap/Accordion";
import { Form } from "react-bootstrap";
import { Line, Bar, PolarArea, Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  RadialLinearScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import "./../assets/sidebar-custom-styles.css"; // Ensure this path is correct

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  RadialLinearScale,
  Title,
  Tooltip,
  Legend
);

const Sidebar = ({
  isVisible,
  closeSidebar,
  usePotentialColor,
  setUsePotentialColor,
  cityData,
  allBuildings,
}) => {
  const [isTransitioning, setIsTransitioning] = useState(false);

  const handleTransitionEnd = () => {
    setIsTransitioning(false);
  };

  const sidebarStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    right: isVisible ? "0" : "-300px",
    top: "0",
    transition: "right 0.3s",
    visibility: isVisible || isTransitioning ? "visible" : "hidden",
    display: isVisible || isTransitioning ? "block" : "none",
    zIndex: 1111,
    overflowY: "auto",
    padding: "20px",
    boxSizing: "border-box",
  };

  const buttonStyle = {
    color: "white",
    marginBottom: "10px",
    cursor: "pointer",
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    border: "none",
    fontSize: "20px",
    fontWeight: "bold",
    padding: "5px 10px",
    borderRadius: "5px",
  };

  const sectionStyle = {
    marginBottom: "20px",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    padding: "10px",
    borderRadius: "5px",
  };

  const handleSwitchChange = () => {
    setUsePotentialColor((prev) => !prev);
  };

  const aggregateCityData = (cities) => {
    const cityCounts = cities.reduce((acc, city) => {
      acc[city] = (acc[city] || 0) + 1;
      return acc;
    }, {});

    const labels = Object.keys(cityCounts);
    const data = Object.values(cityCounts);

    return {
      labels: labels,
      datasets: [
        {
          data: data,
          backgroundColor: [
            "#FF6384",
            "#36A2EB",
            "#FFCE56",
            "#4BC0C0",
            "#9966FF",
            "#FF9F40",
          ],
        },
      ],
    };
  };

  const cityChartData = aggregateCityData(cityData);

  return (
    <Container
      fluid="md"
      className="sidebar-container"
      style={sidebarStyle}
      onTransitionEnd={handleTransitionEnd}
    >
      <button onClick={closeSidebar} style={buttonStyle}>
        ×
      </button>

      {/* Section 1: Building Overview */}
      {allBuildings && allBuildings.length > 0 && (
        <div style={sectionStyle}>
          <h4>Building Overview</h4>
          <p>Height: {allBuildings[0]?.height} m</p>
          <p>Potential: {allBuildings[0]?.potential} kWh</p>
          <p>Z Min Sol: {allBuildings[0]?.z_min_sol}</p>
          <p>Acquisition Method: {allBuildings[0]?.acqu_alti}</p>
        </div>
      )}
      {/* Section 7: Switch */}
      <div style={sectionStyle}>
        <Form>
          <Form.Check
            type="switch"
            id="use-potential-color-switch"
            label={<span className="form-check-label">Default potential</span>}
            checked={usePotentialColor}
            onChange={handleSwitchChange}
          />
        </Form>
      </div>

      {/* Section 8: Range Slider */}
      <div style={sectionStyle}>
        <Row>
          <Form.Label className="form-range-label">Range</Form.Label>
          <Form.Range />
        </Row>
      </div>
      {/* Section 5: Production Coefficients Chart */}
      {allBuildings && allBuildings.length > 0 && (
        <div style={sectionStyle}>
          <h4>Production Coefficients</h4>
          <PolarArea
            data={{
              labels: allBuildings[0]?.production_coefficients.map(
                (coeff) => coeff.angle
              ),
              datasets: [
                {
                  label: "Production Coefficients",
                  data: allBuildings[0]?.production_coefficients.map(
                    (coeff) => coeff.coefficient
                  ),
                  backgroundColor: "rgba(255, 99, 132, 0.2)",
                  borderColor: "rgba(255, 99, 132, 1)",
                  borderWidth: 1,
                },
              ],
            }}
          />
        </div>
      )}
      {/* Section 2: General Data - Building Counts by City */}
      <div style={sectionStyle}>
        <h4>Buildings by City</h4>
        <Pie data={cityChartData} />
      </div>

      {/* Section 3: Yearly Production Chart */}
      {allBuildings && allBuildings.length > 0 && (
        <div style={sectionStyle}>
          <h4>Yearly Production</h4>
          <Line
            data={{
              labels: allBuildings[0]?.yearly_productions.map(
                (prod) => prod.year
              ),
              datasets: [
                {
                  label: "Yearly Production",
                  data: allBuildings[0]?.yearly_productions.map(
                    (prod) => prod.production
                  ),
                  fill: false,
                  backgroundColor: "blue",
                  borderColor: "blue",
                },
              ],
            }}
          />
        </div>
      )}

      {/* Section 4: Monthly Production Chart */}
      {allBuildings && allBuildings.length > 0 && (
        <div style={sectionStyle}>
          <h4>Monthly Production</h4>
          <Bar
            data={{
              labels: allBuildings[0]?.monthly_productions.map(
                (prod) => prod.month
              ),
              datasets: [
                {
                  label: "Monthly Production",
                  data: allBuildings[0]?.monthly_productions.map(
                    (prod) => prod.production
                  ),
                  backgroundColor: "green",
                },
              ],
            }}
          />
        </div>
      )}
    </Container>
  );
};

export default Sidebar;

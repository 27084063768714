// MapSearchControl.jsx
import React, { useState } from "react";
import { Icon } from "react-icons-kit";
import { useMap } from "react-leaflet";
// import L from "leaflet";
import { search as searchIcon } from "react-icons-kit/fa/search";
import { ic_clear as clearIcon } from "react-icons-kit/md/ic_clear";
import "./../assets/map.css";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, BarElement, ArcElement, RadialLinearScale, Title, Tooltip, Legend } from 'chart.js';
import Tour from "../component/Tour"; // Import the Tour component
import tourSteps from "../component/tourSteps"; // Import the tour steps

const MapSearchControl = ({ setAddress }) => {
  const [addressInput, setAddressInput] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const map = useMap();

  const handleSearchChange = async (input) => {
    setAddressInput(input);
    if (input.length > 3) {
      try {
        const encodedAddress = encodeURIComponent(input);
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${encodedAddress}&key=AIzaSyBtkRt1QH4neWoFlbl_FPys-HxrDR7w1Lo`
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setSuggestions(data.results);
      } catch (error) {
        console.error("Error fetching suggestions:", error);
      }
    } else {
      setSuggestions([]);
    }
  };

  const clearSearch = () => {
    setAddressInput("");
    setSuggestions([]);
  };


  const handleSelectSuggestion = (suggestion) => {
    const coords = suggestion.geometry.location;
    const newCenter = [coords.lat, coords.lng];
    const newZoom = 17;
  
    // Set the new center and zoom of the map
    map.setView(newCenter, newZoom);
  
    setAddressInput(suggestion.formatted_address, coords);
    setAddress(suggestion.formatted_address);
    setSuggestions([]);
  };
  


  return (
    <div style={{ position: "relative", height: "100vh", width: "100%" }}>
    <div
      className="am-address-search-widget"
     
    >
      <Icon
        icon={searchIcon}
        size={30}
        style={{ color: "white", marginLeft: "10px" }}
        className="search-icon"
      />
      <input
        type="text"
        value={addressInput}
        className="search-input"
        // onChange={(e) => handleSearchChange(e.target.value)}
        placeholder="Saisissez votre adresse ici"
       
        onFocus={(e) => e.target.classList.add('focused')} // Add class on focus
        // onBlur={(e) => {
        //   e.target.classList.remove('focused'); // Remove class when input loses focus
        // }}
        onChange={(e) => handleSearchChange(e.target.value)}
      />
      {addressInput && (
        <Icon
          icon={clearIcon}
          className="clear-icon"
          size={30}
          style={{
            color: "white",
            backgroundColor: "#323232",
            marginLeft: "10px",
          }}
          onClick={clearSearch}
        />
      )}
      {suggestions.length > 0 && (
        <ul className="suggestions-list">
          {suggestions.map((suggestion, index) => (
            <li key={index} onClick={() => handleSelectSuggestion(suggestion)}>
              {suggestion.formatted_address}
            </li>
          ))}
        </ul>
      )}
    </div>
    </div>
  );
};
export default MapSearchControl;

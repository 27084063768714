// services geoJSON.jsx
import api from "./api.jsx";


export const fetchBuildingsGeoJSON = async () => {
  try {
    const response = await api.get("buildings/geojson/");
    // response.data directly contains the GeoJSON structure shown above
    // console.log('Data:', response.data);
 
    // Directly return the features array for simplicity
    return response.data;
  } catch (error) {
    // console.error('Error fetching buildings GeoJSON:', error);
    throw error;
  }
};

export const fetchBuildingsGeoJSONByUserId = async (userId) => {
  try {
    const response = await api.get(`/user/${userId}/cities/`);
    // console.log(response.data, "this is the response.data.buildings")
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchDashAppURL = async (buildingId) => {
  try {
    const response = await api.get(`dash-url/${buildingId}/`);
    // console.log('Data in the fetchDashAppURL function:', response.data);
    return response.data.url; // Assuming the response contains the HTML for embedding the Dash app
  } catch (error) {
    console.error('Error embedding Dash app:', error);
    throw error;
  }
};
  